@font-face {
  font-family: "regular";
  src: url("../public/font/Pretendard-Regular.woff");
}
@font-face {
  font-family: "medium";
  src: url("../public/font/Pretendard-Medium.woff");
}

body {
  background-color: #141619;
  color: white;
  font-family: regular;
}
